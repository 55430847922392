@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.floor-level {
  z-index: 2000;
  margin-bottom: 25px;
  padding: 8px;
  user-select: none;
}
.floor-level .floor-level__svg-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: calc(10px - 50px);
  pointer-events: none;
}
.floor-level .floor-level__svg-container .floor-level__svg {
  width: 50px;
  height: 50px;
}
.floor-level .floor-level__svg-container .floor-level__floor {
  position: relative;
  cursor: pointer;
  transform: rotateX(-60deg) rotateY(0deg) rotateZ(-45deg);
  transform-origin: center center 0;
  pointer-events: auto;
  fill: url("#gradient-white");
}
.floor-level .floor-level__svg-container--current .floor-level__floor,
.floor-level .floor-level__svg-container--hovered .floor-level__floor {
  fill: url("#gradient-primary") !important;
}
.floor-level .floor-level__svg-container--basement .floor-level__floor {
  fill: url("#gradient-grey");
}
.floor-level .floor-level__svg-container--not-selected .floor-level__floor {
  fill: url("#gradient-silver");
}
.floor-level .floor-level__svg-container .floor-level__label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  padding: 2px 8px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
}
.floor-level .floor-level__svg-container .floor-level__label:after {
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-width: 9.5px 10px 9.5px 0;
  border-style: solid;
  border-color: transparent #000 transparent transparent;
  content: '';
}
.gradient-start,
.gradient-stop {
  stop-opacity: 1;
}
#gradient-white .gradient-start {
  stop-color: #f2f2f2;
}
#gradient-white .gradient-stop {
  stop-color: #fff;
}
#gradient-grey .gradient-start {
  stop-color: #38393a;
}
#gradient-grey .gradient-stop {
  stop-color: #b4b2a9;
}
#gradient-silver .gradient-start {
  stop-color: #dfdbd5;
}
#gradient-silver .gradient-stop {
  stop-color: #f3f1ef;
}
#gradient-primary .gradient-start {
  stop-color: #ee6d76;
}
#gradient-primary .gradient-stop {
  stop-color: #f7bbbf;
}
/*# sourceMappingURL=src/app/views/building-viewer/components/floor-control.css.map */