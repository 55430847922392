@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.ticket-info {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 500px;
}
.ticket-info .header {
  padding: 16px;
}
.ticket-info .header .header-main {
  display: flex;
}
.ticket-info .header .header-main .ticket-title {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
}
.ticket-info .grid-tile__label {
  text-transform: uppercase;
}
.ticket-info .main .ticket-status {
  background-color: #464749;
  color: #fff;
}
.ticket-info .main .button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}
.ticket-info .main .open-ticket {
  transform: rotate(-90deg);
}
.grid-tile__label {
  margin-bottom: 8px;
}
/*# sourceMappingURL=src/app/views/building-viewer/components/ticket-info.css.map */