@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.shortcuts {
  cursor: pointer;
}
.shortcuts-popover {
  padding: 16px;
  width: fit-content;
  box-shadow: none;
}
.shortcuts-popover .popover__title {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 1em;
}
.shortcuts-popover .popover__main {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.shortcuts-popover .popover__main .popover__subtitle {
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 0.8em;
}
.shortcuts-popover .popover__main .shortcut {
  display: flex;
  margin: 8px 0;
}
.shortcuts-popover .popover__main .shortcut:last-child {
  margin-bottom: 0px;
}
.shortcuts-popover .popover__main .shortcut .shortcut__keys {
  display: flex;
  flex-basis: 60%;
  margin-right: 16px;
  text-align: right;
  white-space: nowrap;
}
.shortcuts-popover .popover__main .shortcut .shortcut__keys .key {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 6px;
  background-color: #464749;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 700;
}
.shortcuts-popover .popover__main .shortcut .shortcut__keys .key-plus {
  margin: 0 4px;
  font-size: 1.3em;
}
.shortcuts-popover .popover__main .shortcut .shortcut__action {
  flex-basis: 70%;
  text-align: right;
  font-size: 1.1em;
}
/*# sourceMappingURL=src/app/pages/data/components/shortcuts.css.map */