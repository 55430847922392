
























































































































































































































































































































































































































































































































































@import '~variables'

.layout-page
  height 100vh

.grab-cursor
  cursor grab

.grabbing-cursor
  cursor grabbing

.building-viewer
  position relative
  overflow hidden
  width 100%
  height 100%
  outline none
  background-color #ddd9d3
  #buildingContainer
    height 100%
  .building-viewer__controls
    position absolute
    z-index 1000
    display flex
    flex-direction column
    &--left
      left 20px
    &--right
      top 15px
      right 20px
  .spinner-loader
    stroke black
    fill black
  .building-viewer__display-container
    display flex
    justify-content center
    align-items center
    height 100%
  .building-viewer__message
    text-align center
    font-size 1.4em
    .link
      color $dark
      &:hover
        color $grey
