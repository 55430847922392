@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.device-info,
.device-add {
  padding: 16px;
  cursor: default;
}
.device-info .device-actions,
.device-add .device-actions {
  display: flex;
  align-items: center;
}
.device-info .device-actions .o-button,
.device-add .device-actions .o-button {
  width: auto;
  background-color: #000;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 1.1rem;
}
.device-info .device-title {
  margin: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
  user-select: text;
}
.device-add {
  width: 300px;
}
.device-add .o-input {
  width: 100%;
}
.device-add .device-description {
  height: auto;
  resize: none;
}
.device-add .device-actions .o-button {
  flex: 2;
}
.device-add .device-actions .trash {
  margin-left: 16px;
  width: 18px;
  height: 20px;
  cursor: pointer;
}
/*# sourceMappingURL=src/app/components/devices/device-info.css.map */