






















































































































































@import '~variables'

$square-size = 50px
$square-margin = 10px

.floor-level
  z-index 2000
  margin-bottom 25px
  padding 8px
  user-select none
  .floor-level__svg-container
    position relative
    display flex
    align-items center
    margin-bottom 'calc(%s - %s)' % ($square-margin $square-size)
    pointer-events none
    .floor-level__svg
      width $square-size
      height $square-size
    .floor-level__floor
      position relative
      cursor pointer
      transform rotateX(-60deg) rotateY(0deg) rotateZ(-45deg)
      transform-origin center center 0
      pointer-events auto
      fill url('#gradient-white')
    &--current, &--hovered
      .floor-level__floor
        fill url('#gradient-primary') !important
    &--basement
      .floor-level__floor
        fill url('#gradient-grey')
    &--not-selected
      .floor-level__floor
        fill url('#gradient-silver')
    .floor-level__label
      position relative
      display flex
      justify-content center
      align-items center
      margin-left 24px
      padding 2px 8px
      border-top-right-radius 10px
      border-bottom-right-radius 10px
      background-color $black
      color white
      font-weight 500
      font-size $fs-body
      &:after
        position absolute
        left -10px
        width 0
        height 0
        border-width 9.5px 10px 9.5px 0
        border-style solid
        border-color transparent $black transparent transparent
        content ''

.gradient-start, .gradient-stop
  stop-opacity 1

#gradient-white
  .gradient-start
    stop-color darken(white, 5%)
  .gradient-stop
    stop-color white

#gradient-grey
  .gradient-start
    stop-color darken($dark, 20%)
  .gradient-stop
    stop-color $grey

#gradient-silver
  .gradient-start
    stop-color lighten($silver, 5%)
  .gradient-stop
    stop-color $light

#gradient-primary
  .gradient-start
    stop-color darken($primary, 20%)
  .gradient-stop
    stop-color $primary
