



















































































@import '~variables'

.pop-up-container
  position absolute
  z-index 1000
  min-width 200px
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-elevation-2
  &--top-left
    border-bottom-right-radius 0
  &--top-right
    border-bottom-left-radius 0
  &--bottom-left
    border-top-right-radius 0
  &--bottom-right
    border-top-left-radius 0
  .pop-up__content--top-left
    position relative
    &:after
      position absolute
      top 100%
      right 0
      width 0
      height 0
      border-width 0 30px 30px 0
      border-style solid
      border-color transparent white transparent transparent
      filter drop-shadow(0 3px 2px rgba(0, 0, 0, 0.29))
      content ''
      clip-path inset(0px -10px -10px -10px)
  .pop-up__content--top-right
    position relative
    &:after
      position absolute
      top 100%
      left 0
      width 0
      height 0
      border-top 30px solid white
      border-right 30px solid transparent
      filter drop-shadow(0 3px 2px rgba(0, 0, 0, 0.29))
      content ''
      clip-path inset(0px -10px -10px -10px)
  .pop-up__content--bottom-left
    position relative
    &:after
      position absolute
      top -30px
      right 0
      width 0
      height 0
      border-bottom 30px solid white
      border-left 30px solid transparent
      filter drop-shadow(0 0px 2px rgba(0, 0, 0, 0.29))
      content ''
      clip-path inset(-10px -10px 0px -10px)
  .pop-up__content--bottom-right
    position relative
    &:after
      position absolute
      top -30px
      left 0
      width 0
      height 0
      border-right 30px solid transparent
      border-bottom 30px solid white
      filter drop-shadow(0 0px 2px rgba(0, 0, 0, 0.29))
      content ''
      clip-path inset(-10px -10px 0px -10px)
