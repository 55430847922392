






























































@import '~variables'

.info
  cursor default!important
.action
  color darken($primary, 10)
