

















































































































































































































@import '~variables'

.shortcuts
  cursor pointer

.shortcuts-popover
  padding 16px
  width fit-content
  box-shadow none
  .popover__title
    text-transform uppercase
    letter-spacing 0.5px
    font-weight 700
    font-size 1em
  .popover__main
    display flex
    flex-direction column
    margin-top 5px
    .popover__subtitle
      margin-top 10px
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size 0.8em
    .shortcut
      display flex
      margin 8px 0
      &:last-child
        margin-bottom 0px
      .shortcut__keys
        display flex
        flex-basis 60%
        margin-right 16px
        text-align right
        white-space nowrap
        .key
          display flex
          align-items center
          padding 2px 8px
          border-radius 6px
          background-color $dark
          color white
          letter-spacing 1px
          font-weight 700
        .key-plus
          margin 0 4px
          font-size 1.3em
      .shortcut__action
        flex-basis 70%
        text-align right
        font-size 1.1em
