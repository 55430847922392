@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.floor-rotation {
  position: relative;
  z-index: 1000;
  margin-left: -7px;
}
.floor-rotation .floor-rotation__arrow {
  position: absolute;
  bottom: 0px;
  width: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.floor-rotation .floor-rotation__arrow--left {
  left: 15px;
  transform: scaleX(-1);
}
.floor-rotation .floor-rotation__arrow--left:hover {
  transform: scaleX(-1) scale(1.3);
}
.floor-rotation .floor-rotation__arrow--right {
  left: 42px;
}
.floor-rotation .floor-rotation__arrow--right:hover {
  transform: scale(1.3);
}
.floor-rotation .square {
  opacity: 0.6;
  cursor: pointer;
  fill: #fff;
}
.floor-rotation .square:hover {
  fill: #f7bbbf;
}
.floor-rotation .cube {
  width: 80px;
  height: 80px;
}
.floor-rotation .cube .cube__left,
.floor-rotation .cube .cube__front,
.floor-rotation .cube .cube__top {
  transform-origin: center;
  fill: #fff;
}
.floor-rotation .cube .cube__left {
  cursor: pointer;
  transform: rotateX(30deg) rotateY(40deg);
  fill: #bfbfbf;
}
.floor-rotation .cube .cube__left:hover {
  fill: #f7bbbf;
}
.floor-rotation .cube .cube__front {
  cursor: pointer;
  transform: rotateX(30deg) rotateY(-40deg);
  fill: #e6e6e6;
}
.floor-rotation .cube .cube__front:hover {
  fill: #f7bbbf;
}
.floor-rotation .cube .cube__top {
  cursor: pointer;
  transform: rotateX(-65deg) rotateY(0deg) rotateZ(-45deg) scale(1.07);
}
.floor-rotation .cube .cube__top:hover {
  fill: #f7bbbf;
}
.floor-rotation .cube .cube__direction {
  width: $cube-size;
  height: $cube-size;
  font-size: 25px;
  transform: rotateX(-65deg) rotateY(0deg) rotateZ(-45deg) scale(1.07);
  transform-origin: center;
}
/*# sourceMappingURL=src/app/views/building-viewer/components/view-control.css.map */