





































































































































@import '~variables'

.ticket-info
  display flex
  flex-direction column
  min-width 200px
  max-width 500px
  .header
    padding $space-2
    .header-main
      display flex
      .ticket-title
        text-transform capitalize
        font-weight 700
        font-size $fs-h2
        line-height 1
  .grid-tile__label
    text-transform uppercase
  .main
    .ticket-status
      background-color $dark
      color white
    .button-container
      display flex
      justify-content center
      width 100%
      cursor pointer
    .open-ticket
      transform rotate(-90deg)

.grid-tile__label
  margin-bottom $space-1
