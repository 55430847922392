@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.layout-page {
  height: 100vh;
}
.grab-cursor {
  cursor: grab;
}
.grabbing-cursor {
  cursor: grabbing;
}
.building-viewer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #ddd9d3;
}
.building-viewer #buildingContainer {
  height: 100%;
}
.building-viewer .building-viewer__controls {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
.building-viewer .building-viewer__controls--left {
  left: 20px;
}
.building-viewer .building-viewer__controls--right {
  top: 15px;
  right: 20px;
}
.building-viewer .spinner-loader {
  stroke: #000;
  fill: #000;
}
.building-viewer .building-viewer__display-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.building-viewer .building-viewer__message {
  text-align: center;
  font-size: 1.4em;
}
.building-viewer .building-viewer__message .link {
  color: #464749;
}
.building-viewer .building-viewer__message .link:hover {
  color: #b4b2a9;
}
/*# sourceMappingURL=src/app/views/building-viewer/index.css.map */