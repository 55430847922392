@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.space-info {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}
.space-info .header .header-main {
  display: flex;
  padding: 20px;
}
.space-info .header .header-main .space-name {
  margin: 0;
  margin-right: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
}
.space-info .header .header-main .space-surface {
  font-weight: 100;
}
.space-info .device-list {
  padding: 10px 20px 3px 20px;
}
.space-info .device-list .devices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 120px;
  max-width: 155px;
  overflow-y: auto;
}
.space-info .zones-types-container {
  display: flex;
}
.space-info .zone-name {
  display: inline-block;
  padding: 1px 6px;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 0.9rem;
}
.space-info .zone-name .zone-name--text {
  background-clip: text;
  -webkit-background-clip: text !important;
}
.space-info .space-data-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid #ddd9d3;
}
.space-info .space-data-list .space-data {
  margin-right: 4px;
  margin-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
  max-width: 350px;
  width: fit-content;
  border: 1px solid #ded9d3;
}
.space-info .space-data-list .space-data__profile {
  margin-right: 4px;
  font-weight: 100;
  font-size: 1.3rem;
}
.space-info .space-data-list .space-data__value {
  font-weight: 400;
  font-size: 1.3rem;
}
.space-info .space-info__content {
  justify-content: center;
  padding: 20px;
}
.space-info .space-info__content .add-device {
  width: fit-content;
  background-color: #fff;
  text-transform: uppercase;
}
.space-info .space-info__content .space-info__message {
  font-weight: 700;
}
.space-info .space-tags {
  max-width: 350px;
  word-break: break-all;
}
.space-info .space-tags .tag {
  display: inline-block;
  margin-right: 2px;
  padding: 1px 4px;
  border: solid 2px #f3f1ef;
  border-radius: 20px;
  background-color: #fff;
  color: #464749;
}
.space-info .user-nodes {
  margin-bottom: 8px;
  padding-bottom: 8px;
  max-width: 350px;
  border-bottom: 1px solid #ddd9d3;
  word-break: break-all;
}
.space-info .user-nodes .user-node {
  margin-top: 2px;
  margin-right: 2px;
  padding: 1px 4px;
  border: solid 2px #f3f1ef;
  border-radius: 20px;
  background-color: #fff;
  color: #464749;
}
.space-info .data-tile {
  padding: 8px 20px;
}
.space-info .data-tile .grid-tile__value {
  font-weight: 700;
  font-size: 1.3rem;
}
.grid-tile__label {
  margin-bottom: 4px;
}
.grid-tile {
  border-right: none;
  border-left: 1px solid #f4f4f4;
}
.zone-tile,
.type-tile {
  padding: 8px 20px;
}
/*# sourceMappingURL=src/app/components/spaces/space-info.css.map */