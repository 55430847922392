@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.pop-up-container {
  position: absolute;
  z-index: 1000;
  min-width: 200px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.pop-up-container--top-left {
  border-bottom-right-radius: 0;
}
.pop-up-container--top-right {
  border-bottom-left-radius: 0;
}
.pop-up-container--bottom-left {
  border-top-right-radius: 0;
}
.pop-up-container--bottom-right {
  border-top-left-radius: 0;
}
.pop-up-container .pop-up__content--top-left {
  position: relative;
}
.pop-up-container .pop-up__content--top-left:after {
  position: absolute;
  top: 100%;
  right: 0;
  width: 0;
  height: 0;
  border-width: 0 30px 30px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  filter: drop-shadow(0 3px 2px rgba(0,0,0,0.29));
  content: '';
  clip-path: inset(0px -10px -10px -10px);
}
.pop-up-container .pop-up__content--top-right {
  position: relative;
}
.pop-up-container .pop-up__content--top-right:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-top: 30px solid #fff;
  border-right: 30px solid transparent;
  filter: drop-shadow(0 3px 2px rgba(0,0,0,0.29));
  content: '';
  clip-path: inset(0px -10px -10px -10px);
}
.pop-up-container .pop-up__content--bottom-left {
  position: relative;
}
.pop-up-container .pop-up__content--bottom-left:after {
  position: absolute;
  top: -30px;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 30px solid #fff;
  border-left: 30px solid transparent;
  filter: drop-shadow(0 0px 2px rgba(0,0,0,0.29));
  content: '';
  clip-path: inset(-10px -10px 0px -10px);
}
.pop-up-container .pop-up__content--bottom-right {
  position: relative;
}
.pop-up-container .pop-up__content--bottom-right:after {
  position: absolute;
  top: -30px;
  left: 0;
  width: 0;
  height: 0;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #fff;
  filter: drop-shadow(0 0px 2px rgba(0,0,0,0.29));
  content: '';
  clip-path: inset(-10px -10px 0px -10px);
}
/*# sourceMappingURL=src/app/components/ui/pop-up.css.map */