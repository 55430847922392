















































































































































































































































































@import '~variables'

.space-info
  display flex
  flex-direction column
  min-width 200px
  .header
    .header-main
      display flex
      padding 20px
      .space-name
        margin 0
        margin-right 16px
        text-transform uppercase
        font-weight 700
        font-size $fs-h1
        line-height 1
      .space-surface
        font-weight 100
  .device-list
    padding 10px 20px 3px 20px
    .devices
      display flex
      flex-direction row
      flex-wrap wrap
      max-height 120px
      max-width 155px
      overflow-y auto
  .zones-types-container
    display flex
  .zone-name
    display inline-block
    padding 1px 6px
    border-radius 20px
    text-transform uppercase
    font-size 0.9rem
    .zone-name--text
      background-clip text
      -webkit-background-clip text !important
  .space-data-list
    display flex
    flex-wrap wrap
    margin-bottom 8px
    padding-bottom 4px
    border-bottom $border-light solid $silver
    .space-data
      margin-right 4px
      margin-bottom 4px
      padding-right 8px
      padding-left 8px
      max-width 350px
      width fit-content
      border 1px solid darken($light, 10%)
      &__profile
        margin-right 4px
        font-weight 100
        font-size $fs-h2
      &__value
        font-weight 400
        font-size $fs-h2
  .space-info__content
    justify-content center
    padding 20px
    .add-device
      width fit-content
      background-color white
      text-transform uppercase
    .space-info__message
      font-weight 700
  .space-tags
    max-width 350px
    word-break break-all
    .tag
      display inline-block
      margin-right 2px
      padding 1px 4px
      border solid 2px $light
      border-radius 20px
      background-color white
      color $dark
  .user-nodes
    margin-bottom 8px
    padding-bottom 8px
    max-width 350px
    border-bottom $border-light solid $silver
    word-break break-all
    .user-node
      margin-top 2px
      margin-right 2px
      padding 1px 4px
      border solid 2px $light
      border-radius 20px
      background-color white
      color $dark
  .data-tile
    padding $space-1 20px
    .grid-tile__value
      font-weight 700
      font-size $fs-h2

.grid-tile__label
  margin-bottom $space-little

.grid-tile
  border-right none
  border-left $grid-border

.zone-tile, .type-tile
  padding $space-1 20px
