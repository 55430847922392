






















































































































































































































@import '~variables'

.device-info, .device-add
  padding 16px
  cursor default
  .device-actions
    display flex
    align-items center
    .o-button
      width auto
      background-color black
      color white
      text-align center
      text-transform uppercase
      letter-spacing 0.5px
      font-weight 700
      font-size $fs-h3

.device-info
  .device-title
    margin 0
    margin-bottom 16px
    text-transform uppercase
    font-weight 700
    font-size $fs-h2
    line-height 1
    user-select text

.device-add
  width 300px
  .o-input
    width 100%
  .device-description
    height auto
    resize none
  .device-actions
    .o-button
      flex 2
    .trash
      margin-left 16px
      width 18px
      height 20px
      cursor pointer
