





































































































@import '~variables'

.floor-rotation
  position relative
  z-index 1000
  margin-left -7px
  .floor-rotation__arrow
    position absolute
    bottom 0px
    width 20px
    cursor pointer
    transition transform 0.3s ease
  .floor-rotation__arrow--left
    left 15px
    transform scaleX(-1)
    &:hover
      transform scaleX(-1) scale(1.3)
  .floor-rotation__arrow--right
    left 42px
    &:hover
      transform scale(1.3)
  .square
    opacity 0.6
    cursor pointer
    fill white
    &:hover
      fill $primary
  .cube
    width 80px
    height 80px
    .cube__left, .cube__front, .cube__top
      transform-origin center
      fill white
    .cube__left
      cursor pointer
      transform rotateX(30deg) rotateY(40deg)
      fill darken(white, 25%)
      &:hover
        fill $primary
    .cube__front
      cursor pointer
      transform rotateX(30deg) rotateY(-40deg)
      fill darken(white, 10%)
      &:hover
        fill $primary
    .cube__top
      cursor pointer
      transform rotateX(-65deg) rotateY(0deg) rotateZ(-45deg) scale(1.07)
      &:hover
        fill $primary
    .cube__direction
      width $cube-size
      height $cube-size
      font-size 25px
      transform rotateX(-65deg) rotateY(0deg) rotateZ(-45deg) scale(1.07)
      transform-origin center
